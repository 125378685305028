// this file can be deleted once graphql-codegen is implemented
// https://onemedical.atlassian.net/browse/CQ-3864

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

export type ChartingTemplatePurpose = 'PERSONAL' | 'PUBLIC' | 'AUTO_LABS';

export type ChartingTemplateType = 'MESSAGE' | 'TEXT';

export interface ChartingTemplate {
  id: string;
  name: string;
  body: string;
  purpose: ChartingTemplatePurpose;
  type: ChartingTemplateType;
  tags: string[];
  internalUser: { id: string } | null;
  updatedAt: string;
  updatedBy: string;
}

export interface ChartingTemplateSearchParams {
  type: ChartingTemplateType;
  purposes?: ChartingTemplatePurpose[];
  tags?: string[];
  terms?: string;
  first?: number;
  offset?: number;
}

export interface SeachChartingTemplatesVariables {
  params: ChartingTemplateSearchParams;
}

export interface ChartingTemplateEdge {
  node: ChartingTemplate;
}

export interface ChartingTemplateConnection {
  edges: ChartingTemplateEdge[];
  total: number;
}

export interface SearchChartingTemplates {
  templates: ChartingTemplateConnection;
}

@Injectable()
export class SearchChartingTemplatesQuery {

  constructor(private readonly apollo: Apollo) { }

  fetch(variables: SeachChartingTemplatesVariables): Observable<ApolloQueryResult<SearchChartingTemplates>> {
    const query = gql`
      query SearchChartingTemplates($params: ChartingTemplateSearchParams!) {
        templates: chartingTemplates(params: $params) {
          total
          edges {
            node {
              id
              name
              body
              type
              purpose
              tags
              internalUser {
                id
              }
              updatedAt
              updatedBy
            }
          }
        }
      }
    `;
    return this
      .apollo
      .query<SearchChartingTemplates, SeachChartingTemplatesVariables>({
        query,
        variables,
      });
  }
}
