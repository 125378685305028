import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { ResultSet } from '@app/shared/models/result-set';
import { SearchCriteria } from '@app/shared/models/search-criteria';
import { Template } from '@app/shared/models/template';
import { TemplateType } from '../models/template-type';
import { Tag } from '@app/features/tag/shared/tag.type';
import { SearchFilterPurposeOption } from '../models/search-filter-purpose-option';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ChartingTemplatesSource, mapChartingTemplate, mapSearchCriteria } from './search-utils';
import { SearchChartingTemplatesQuery } from './graphql-types';

@Injectable()
export class TemplateSearchService {

  constructor(
    private searchService: SearchService,
    private config: ConfigService,
    private launchdarkly: LaunchDarklyService,
    private query: SearchChartingTemplatesQuery,
  ) { }

  search(criteria: SearchCriteria): Observable<ResultSet<Template>> {
    const source = this
      .launchdarkly
      .variation<ChartingTemplatesSource>('charting-templates-source', 'opensearch-proxy');
    if (source === 'opensearch-proxy') {
      return this.legacySearch(criteria);
    } else {
      const params = mapSearchCriteria(criteria);
      return this
        .query
        .fetch({ params })
        .pipe(
          map(result => ({
            items: result.data.templates.edges.map(edge => mapChartingTemplate(edge.node)),
            total: result.data.templates.total,
            limit: criteria.limit,
            offset: criteria.offset,
          })),
        );
    }
  }

  private legacySearch(criteria: SearchCriteria): Observable<ResultSet<Template>> {
    const index = this.config.searchIndex(criteria.index);
    return this
      .searchService
      .search(
        this.buildSearchQuery(
          index,
          criteria.filters.searchTerm,
          criteria.filters.purpose,
          criteria.userId,
          criteria.filters.selectedTags,
          criteria.limit.toString(),
          criteria.offset.toString(),
        )
      )
      .pipe(
        map(response => {
          const hits = response.hits || {};
          const items = hits.hits || [];
          return {
            total: typeof hits.total?.value === 'number' ? hits.total.value : hits.total,
            items: items.map(hit => this.mapSearchResultToEntity(hit._source, criteria.type)),
            offset: criteria.offset,
            limit: criteria.limit,
          };
        })
      );
  }

  private buildSearchQuery(
    index: string,
    text: string,
    purpose: SearchFilterPurposeOption,
    internalUserId: number,
    selectedTags: Tag[],
    size: string,
    from: string
  ): QueryBuilder {

    const must = [];
    const should = [];

    if (purpose === 'all') {
      should.push(
        { terms: { purpose: ['public', 'auto_labs'] } },
        { term: { internal_user_id: internalUserId } },
      );
    } else {
      must.push({ term: { purpose } });
      if (purpose === 'personal') {
        must.push({ term: { internal_user_id: internalUserId } });
      }
    }
    if (selectedTags && selectedTags.length) {
      const tagClauses = selectedTags.map(tag => ({ term: { tags: tag } }));
      Array.prototype.push.apply(must, tagClauses);
    }
    const filter = {
      bool: { must, should }
    };

    if (text) {
      return new QueryBuilder('multi_match_with_fields_v6_strategy').build(text, {
        size,
        from,
        fields: ['name'],
        sort: ['_score', 'name.keyword'],
        operator: 'and',
        index: [index],
        filter,
      });
    }
    return new QueryBuilder('query_string_with_fields_v6').build('*', {
      size,
      from,
      fields: ['name'],
      sort: ['name.keyword'],
      index: [index],
      filter,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapSearchResultToEntity(data: any, type: TemplateType): Template {
    return {
      id: data ? data.id : null,
      internalUserId: data ? data.internal_user_id : null,
      name: data ? data.name : null,
      body: data ? data.body : null,
      updatedAt: data ? data.updated_at : null,
      updatedBy: (data && data.updated_by) || '-',
      tags: data ? data.tags : null,
      purpose: data ? data.purpose : null,
      type,
    };
  }
}
