export const environment = {
  production: true,
  oauth2: {
    redirectSubPath: '',
    providerUrl: 'https://staging.1life.com',
    clientId: 'z0gMgpC-yckDT1AuYh0fyqYCrlCkyx3oXIuW1iCBnf8',
  },
  onelife: {
    graphql: {
      uri: 'https://staging.1life.com/api/graphql'
    }
  },
  apiServer: 'https://staging.1life.com/api',
  search: {
    proxyHost: 'https://es-proxy-staging.stage.1life.com:443',
    indexes: {
      message_templates: 'message_templates',
      note_templates: 'note_templates',
      text_templates: 'text_templates',
    },
  },
  links: {
    onelife: 'https://staging.1life.com',
    admin: 'https://staging.1life.com/admin',
    tasks: 'https://staging-admin.1life.com/schedule/tasks',
    appointmentScanner:
      'https://staging-admin.1life.com/schedule/appointment-scanner',
    unregisteredPatients:
      'https://staging.1life.com/admin/appointments/unregistered',
    virtualVisits: 'https://staging.1life.com/admin/video_appointments',
    docs: 'https://staging.1life.com/admin/inbox',
    faxes: 'https://staging.1life.com/fax/send_fax',
    contacts: 'https://staging-admin.1life.com/admin/contacts',
    providers: 'https://staging-admin.1life.com/admin/provider-directory',
    myAccount: 'https://staging.1life.com/admin/my_admin_account',
  },
  linkTemplates: {
    appointments:
      'https://staging-admin.1life.com/schedule/appointments/office/{OFFICE_ID}',
    providerSchedule:
      'https://staging.1life.com/admin/providers/{USER_ID}/appointments',
    walkInVisits:
      'https://staging.1life.com/admin/offices/{OFFICE_ID}/walkin_visits?date={DATE}',
    officeSchedule:
      'https://staging.1life.com/admin/offices/{OFFICE_ID}/schedules?date={DATE}',
  },
  launchdarkly: {
    clientSideId: '661993796283820effc34d0c',
  },
  sentry: {
    dsn: 'https://91cf2f1a0c01b88f359502eb6109e0e0@o4506221115408384.ingest.us.sentry.io/4508208072884224',
    enabled: true,
    environment: 'staging'
  },
  auth0: {
    domain: 'login.stage.1life.com',
    clientId: 'JLd1cHTCa86tWjNDPqYRlmYMHwdVzVnd',
    audience: 'https://onemedical.com',
    connection: 'okta-saml',
    cacheLocation: 'memory',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://staging.1life.com/api/*',
          tokenOptions: {
            audience: 'https://onemedical.com',
          },
        },
      ],
    },
  },
};
