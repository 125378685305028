import { Template } from '../models/template';
import { TemplatePurpose } from '../models/template-purpose';
import { TemplateType } from '../models/template-type';
import {
  ChartingTemplate,
  ChartingTemplateType,
  ChartingTemplatePurpose,
  ChartingTemplateSearchParams,
} from '../services/graphql-types';
import { SearchFilterPurposeOption } from '../models/search-filter-purpose-option';
import { Tag } from '../../features/tag/shared/tag.type';
import { SearchCriteria } from '../models/search-criteria';

export type ChartingTemplatesSource = 'opensearch-proxy' | 'onelife-monolith-graphql';

export function mapSearchCriteria(source: SearchCriteria): ChartingTemplateSearchParams {
  const params: ChartingTemplateSearchParams = {
    type: mapTemplateType(source.type),
    purposes: mapSearchFilterPurposeOption(source.filters.purpose),
    first: source.limit,
    offset: source.offset,
  };
  if (source.filters.searchTerm) {
    params.terms = source.filters.searchTerm;
  }
  if (source.filters.selectedTags?.length) {
    params.tags = mapTags(source.filters.selectedTags);
  }
  return params;
}

export function mapChartingTemplate(source: ChartingTemplate): Template {
  const template: Template = {
    id: parseInt(source.id, 10),
    name: source.name,
    type: mapChartingTemplateType(source.type),
    body: source.body,
    purpose: mapChartingTemplatePurpose(source.purpose),
    tags: source.tags,
    internalUserId: source.internalUser ? parseInt(source.internalUser.id, 10) : undefined,
    updatedAt: source.updatedAt,
    updatedBy: source.updatedBy,
  };
  return template;
}

export function mapTags(source: Tag[]): string[] {
  return source.map(tag => tag.name);
}

export function mapChartingTemplateType(source: ChartingTemplateType): TemplateType {
  switch (source) {
  case 'MESSAGE':
    return 'message';
  case 'TEXT':
    return 'text';
  }
}

export function mapTemplateType(source: TemplateType): ChartingTemplateType {
  switch (source) {
  case 'message':
    return 'MESSAGE';
  case 'text':
    return 'TEXT';
  }
}

export function mapChartingTemplatePurpose(source: ChartingTemplatePurpose): TemplatePurpose {
  switch (source) {
  case 'PERSONAL':
    return 'personal';
  case 'PUBLIC':
    return 'public';
  case 'AUTO_LABS':
    return 'auto_labs';
  }
}

export function mapSearchFilterPurposeOption(source: SearchFilterPurposeOption): ChartingTemplatePurpose[] {
  switch (source) {
  case 'all':
    return ['PUBLIC', 'PERSONAL', 'AUTO_LABS'];
  case 'public':
    return ['PUBLIC'];
  case 'personal':
    return ['PERSONAL'];
  case 'auto_labs':
    return ['AUTO_LABS'];
  }
}
