import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@environments/environment';

function onelife(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri: environment.onelife.graphql.uri,
    }),
    cache: new InMemoryCache({
      typePolicies: {
        ChartingTemplate: {
          keyFields: ['type', 'id'],
        },
      },
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: onelife,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
