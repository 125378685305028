import { TagSelectors } from '@app/features/tag/store/tag.selectors';
import { Injectable, Injector } from '@angular/core';
import { TemplateService } from './template.service';
import { Observable, noop, combineLatest } from 'rxjs';
import { NgbModalRef, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap, first, map, withLatestFrom, take } from 'rxjs/operators';
import { ProfileSelectors } from '@app/core/profile';
import { Template } from '@app/shared/models/template';
import { TemplateModalDeleteComponent } from '@app/shared/components/template-modal-delete/template-modal-delete.component';
import { TemplateModalComponent } from '../components/template-modal/template-modal.component';
import { TemplateContextToken } from '../tokens/template-context.token';
import { TemplateContext } from '../models/template-context';

@Injectable()
export class TemplateModalService {

  constructor(
    private ngbModal: NgbModal,
    private templateService: TemplateService,
    private profileSelectors: ProfileSelectors,
    private tagSelectors: TagSelectors,
  ) { }

  openTemplate(id: number, injector: Injector): Observable<NgbModalRef> {
    const context: TemplateContext = injector.get(TemplateContextToken) as TemplateContext;
    const template$ = context.selectors.getById(id);
    const tags$ = this.tagSelectors.entities;
    const profile$ = this.profileSelectors.profile;

    const options: NgbModalOptions = {
      centered: true,
      size: 'lg',
      injector,
    };

    return template$
      .pipe(
        tap(template => template ? noop() : context.actions.getById(id)),
        first(template => template != null),
        withLatestFrom(tags$, profile$),
        map(([template, tags, profile]) => {
          if (!this.templateService.viewable(template, profile)) {
            throw new Error('BAD USER! YOU ARE TRYING TO ACCESS SOMETHING YOU CANNOT VIEW!!');
          }

          const modal = this.ngbModal.open(TemplateModalComponent, options);
          modal.componentInstance.mode = this.templateService.editable(template, profile) ? 'edit' : 'view';
          modal.componentInstance.template = template;
          modal.componentInstance.tags = tags;
          modal.componentInstance.user = profile;
          return modal;
        })
      );
  }

  openCreateTemplateModal(injector: Injector): Observable<NgbModalRef> {
    const tags$ = this.tagSelectors.entities;
    const profile$ = this.profileSelectors.profile;
    const context: TemplateContext = injector.get(TemplateContextToken) as TemplateContext;

    const options: NgbModalOptions = {
      centered: true,
      size: 'lg',
      injector,
    };

    return combineLatest(profile$, tags$)
      .pipe(
        take(1),
        map(([profile, tags]) => {
          const template: Template = {
            name: '',
            body: '',
            internalUserId: profile.id,
            updatedAt: null,
            updatedBy: null,
            id: null,
            tags: [],
            type: context.type,
            purpose: 'personal',
          };
          const modal = this.ngbModal.open(TemplateModalComponent, options);
          modal.componentInstance.user = profile;
          modal.componentInstance.mode = 'create';
          modal.componentInstance.template = template;
          modal.componentInstance.tags = tags;
          return modal;
        })
      );
  }

  openTemplateDeleteModal(template: Template, injector: Injector): NgbModalRef {
    const options: NgbModalOptions = {
      centered: true,
      size: '700px',
      injector,
    };
    const modal = this.ngbModal.open(TemplateModalDeleteComponent, options);
    modal.componentInstance.template = template;
    return modal;
  }
}
